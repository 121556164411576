import { gql } from '@apollo/client';

export const GET_OS_LIST = gql`
  query GetOrderServices(
    $options: PaginatorOption
    $filters: FilterOrderServicesPaginator
    $disableQueryFull: Boolean
  ) {
    paginator: getOrderServicesPaginator(
      options: $options
      filters: $filters
      disableQueryFull: $disableQueryFull
    ) {
      data {
        id
        number_request_internal
        metreage
        date_solicitation
        hour_report_delivery
        date_finish_report
        date_finish_inspection
        date_end_delivery_report
        date_os_finish
        date_schedule_inspection
        date_inspection
        date_canceled
        finality_inspection
        isCautelar
        user {
          id
          name
        }
        inspection {
          id
          dateAcceptInspection
          dateRejectInspection
          dateSaveInspectionMobile
          dateSyncInspectionMobile
          pathReportOrderService
          pathReportOrderServiceWithoutPhoto
          pathReportContestation
          newPath
          contestation {
            id
            createdAt
            dateStartSponsor
          }
          inspector {
            id
            user {
              name
            }
          }
        }
        address_inspection {
          address
          number
          neighborhood
          complement
        }
        orderServiceCanceled {
          description
          isGenerateConsumption
          user {
            id
            name
          }
        }
      }
      paginationInfo {
        count
        currentPage
        lastPage
        perPage
        total
      }
    }
  }
`;

export interface GetOrderServicesPaginator {
  paginator: OrderServicesPaginator;
}

interface OrderServicesPaginator {
  data: {
    id: number;
    number_request_internal: string;
    metreage: any;
    date_solicitation: any;
    hour_report_delivery: any;
    date_finish_report: any;
    date_finish_inspection: any;
    date_end_delivery_report: any;
    date_os_finish: any;
    date_schedule_inspection: any;
    date_inspection: any;
    date_canceled: any;
    finality_inspection: any;
    isCautelar: any;
    user: {
      id: any;
      name: any;
    };
    inspection: {
      id: string;
      dateAcceptInspection?: string;
      dateRejectInspection?: string;
      dateSaveInspectionMobile?: string;
      dateSyncInspectionMobile?: string;
      pathReportOrderService?: string;
      pathReportOrderServiceWithoutPhoto?: string;
      pathReportContestation?: string;
      newPath?: boolean;
      contestation: {
        id: string;
        createdAt: string;
        dateStartSponsor: string;
      };
      inspector: {
        id: string;
        user: {
          name: string;
        };
      };
    };
    address_inspection: {
      address: any;
      number: any;
      neighborhood: any;
      complement: any;
    };
    orderServiceCanceled: {
      description: any;
      isGenerateConsumption: any;
      user: {
        id: any;
        name: any;
      };
    };
  }[];
  paginationInfo: {
    count: number;
    currentPage: number;
    lastPage: number;
    perPage: number;
    total: number;
  };
}
