import React from 'react';

import { createBrowserRouter, Outlet, redirect } from 'react-router-dom';
import loadableComponent, { DefaultComponent } from '@loadable/component';
import { withAuth } from './with-auth';
import { withAdmin } from './with-admin';

import { loader } from '../components/secured/ServiceOrderList/loader';

const loadable = (
  loadFn: (props: unknown) => Promise<DefaultComponent<unknown>>
) =>
  loadableComponent(loadFn, {
    fallback: (
      <div className="h-100 d-flex align-items-center justify-content-center">
        <div className="example">
          <div className="sk-grid">
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
            <div className="sk-grid-cube"></div>
          </div>
        </div>
      </div>
    ),
  });

const EditInspection = loadable(
  () => import('../components/secured/Inspection/EditInspection')
);
const ContestationInspection = loadable(
  () => import('../components/secured/Inspection/ContestInspection')
);
const UserEdit = loadable(() => import('../components/secured/UserEdit'));
const AppContainer = loadable(
  () => import('../components/structure/AppContainer')
);
const Dashboard = loadable(() => import('../components/secured/Dashboard'));
const ServiceOrder = loadable(
  () => import('../components/secured/ServiceOrder')
);
const ServiceOrderList = loadable(
  () => import('../components/secured/ServiceOrderList')
);
const StartInspection = loadable(
  () => import('../components/secured/Inspection/StartInspection')
);
const SpaceInspection = loadable(
  () => import('../components/secured/Inspection/SpaceInspection')
);
const PanelInspection = loadable(
  () => import('../components/public/PanelInspection')
);
const Ranking = loadable(() => import('../components/secured/Ranking'));

const Login = loadable(() => import('../components/public/Login'));

export enum AdminRoutes {
  customersList = '/admin/customers/list',
  customersRegister = '/admin/customers/:enterpriseId?',
  customersNew = '/admin/customers/',
  report = '/admin/report',
  osDetails = '/admin/os/:osId',
  customerPack = '/admin/customers/:enterpriseId/pack',
  calendar = '/admin/calendar',
}

const CustomersList = loadable(
  () => import('../components/secured/Admin/CustomersList')
);
const CustomerPack = loadable(
  () => import('../components/secured/Admin/CustomerPack')
);
const CustomerNew = loadable(
  () => import('../components/secured/Admin/CustomerNew')
);
const Report = loadable(() => import('../components/secured/Admin/Report'));
const OSOverview = loadable(
  () => import('../components/secured/Admin/OSOverview')
);
const Calendar = loadable(() => import('../components/secured/Admin/Calendar'));
const Avulso = loadable(() => import('../components/secured/Avulso'));

export const router = createBrowserRouter([
  { path: '/login', element: <Login /> },
  {
    element: <AppContainer />,
    loader: withAuth(),
    children: [
      {
        path: '/dashboard',
        loader: withAuth(),
        element: <Dashboard />,
        errorElement: () => <div>Erro no Dashboard</div>,
      },
      { path: '/ranking', loader: withAuth(), element: <Ranking /> },
      { path: '/new-os', loader: withAuth(), element: <ServiceOrder /> },
      {
        path: '/os-list/avulso',
        loader: withAuth(),
        element: <Avulso />,
      },
      {
        path: '/os-list/:status',
        loader: withAuth(loader),
        element: <ServiceOrderList />,
      },
      {
        path: '/inspection/:id',
        loader: withAuth(),
        element: <StartInspection />,
      },
      {
        path: '/inspection/:id/edit',
        loader: withAuth(),
        element: <EditInspection />,
      },
      {
        path: '/inspection/:osId/contest',
        loader: withAuth(),
        element: <ContestationInspection />,
      },
      {
        path: '/space-inspection/:osId/:spaceId',
        loader: withAuth(),
        element: <SpaceInspection />,
      },
      {
        path: '/panel-inspection/:id*',
        loader: withAuth(),
        element: <PanelInspection />,
      },
      { path: '/user/edit', loader: withAuth(), element: <UserEdit /> },
      { path: '/', loader: () => redirect('/dashboard') },
      {
        path: '/admin',
        element: <Outlet />,
        children: [
          {
            path: '/admin/customers/list',
            element: <CustomersList />,
            loader: withAdmin(),
          },
          {
            path: '/admin/customers/:enterpriseId/pack',
            element: <CustomerPack />,
            loader: withAdmin(),
          },
          {
            path: '/admin/customers/:enterpriseId?',
            element: <CustomerNew />,
            loader: withAdmin(),
          },
          {
            path: '/admin/report',
            element: <Report />,
            loader: withAdmin(),
          },
          {
            path: '/admin/os/:osId',
            element: <OSOverview />,
            loader: withAdmin(),
          },
          {
            path: AdminRoutes.calendar,
            element: <Calendar />,
            loader: withAdmin(),
          },
        ],
      },
    ],
  },
]);
